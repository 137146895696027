import axios from 'axios';
import { uploadFile } from './files';
import { HttpQuery } from '../types/http';
import { Stipulation } from '../types/stipulations';

export const uploadFileAndUpdateStipulation = (
  setProgress: React.Dispatch<React.SetStateAction<number>>
): (({
  file,
  stipulationId,
}: {
  file: File;
  stipulationId: string;
}) => Promise<Stipulation>) => {
  return async ({ file, stipulationId }) => {
    const uploadedFileResult = await uploadFile(
      file,
      stipulationId,
      setProgress
    );
    return uploadedFileResult?.result?.stipulation;
  };
};

export const getStipulations = (
  query?: HttpQuery
): (() => Promise<{ stipulations: Stipulation[]; names: string }>) => {
  return async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/stipulation/`,
      {
        headers: {
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return {
      stipulations: response.data.result?.docs ?? [],
      names: response.data.result.names,
    };
  };
};
