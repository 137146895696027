import { useReducer, useCallback } from 'react';
import { enhancedReducer } from './reducers/';
import { defaultState } from '../contexts/FormContext';
import { FormState } from '../contexts/types';

export default function GlobalStateManager(): [
  FormState,
  ({
    value,
    name,
    type,
  }: {
    value: string | null | number | object | boolean;
    name: string;
    type: string;
  }) => void
] {
  const [state, updateState] = useReducer(enhancedReducer, defaultState);

  const updateForm = useCallback(
    ({
      value,
      name,
      type,
    }: {
      value: string | null | number | object | boolean;
      name: string;
      type: string;
    }) => {
      updateState({
        _path: name.split('.'),
        _value: value,
      });
    },
    []
  );

  return [state, updateForm];
}
