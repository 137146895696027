import Axios from 'axios';
import SparkMD5 from 'spark-md5';
import { convertBlobToBase64 } from '../utils/functions';
import { Stipulation } from '../types/stipulations';

export const uploadFile = async (
  file: File,
  stipulationId: string,
  setProgress?: React.Dispatch<React.SetStateAction<number>>
): Promise<{
  result: {
    fileUrl: string;
    metadata: object;
    name: string;
    stipulation: Stipulation;
  };
}> => {
  const chunkSize = 1024 * 1024 * 10; // 10MB chunks
  const totalChunks = Math.ceil(file.size / chunkSize);
  const checksum = await calculateChecksum(file, totalChunks, chunkSize);
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);

      const base64chunk = await convertBlobToBase64(chunk);

      try {
        const resp = await Axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/stipulation/${stipulationId}/upload_file`,
          {
            totalChunks,
            chunkNumber: i,
            filename: file.name,
            filetype: file.type,
            checksum,
            chunk: (base64chunk as string).split(',')[1],
          },
          {
            withCredentials: true, // make the files to be send to the same instance because of cookie
          }
        );
        const progress = (i + 1) / totalChunks;

        setProgress?.(Math.round(progress * 100));
        if (resp?.data?.result?.fileUrl) {
          resolve(resp.data);
        }
      } catch (err) {
        setProgress?.(0);
        reject(err);
      }
    }
  });
};

const calculateChecksum = (
  file: File,
  totalChunks: number,
  chunkSize: number
) => {
  return new Promise((resolve) => {
    const blobSlice = File.prototype.slice;
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    fileReader.onload = function (e) {
      if (e?.target?.result) {
        spark.append(e.target.result as ArrayBuffer);
      }
      currentChunk++;

      if (currentChunk < totalChunks) {
        loadNext();
      } else {
        resolve(spark.end());
      }
    };

    fileReader.onerror = function (e) {
      console.warn(e);
    };

    function loadNext() {
      const start = currentChunk * chunkSize;
      const end =
        start + chunkSize >= file.size ? file.size : start + chunkSize;

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }

    loadNext();
  });
};
