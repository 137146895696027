import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LayoutWrapper } from '../features/LayoutWrapper/';
import { FormContext, RequestContext } from '../contexts/';
import { NotFound } from '../features/NotFound';
import GlobalStateManager from '../utils/GlobalStateManager';
import RequestStateManager from '../utils/RequestStateManager';
import isUrlValid from '../utils/isUrlValid';
import UploadPage from '../features/UploadPage/UploadPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
function App() {
  const [state, setState] = GlobalStateManager();
  const [requestState, dispatch] = RequestStateManager();
  return (
    <QueryClientProvider client={queryClient}>
      <FormContext.Provider value={[state, setState]}>
        <RequestContext.Provider value={[requestState, dispatch]}>
          {isUrlValid() ? (
            <Switch>
              <Route path="/application/new/" component={LayoutWrapper} />
              <Route component={NotFound} />
            </Switch>
          ) : (
            <Switch>
              <Route
                path="/stipulations/:dealId/:type"
                component={UploadPage}
              />
              <Route component={NotFound} />
            </Switch>
          )}
        </RequestContext.Provider>
      </FormContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
