import React from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetStipulations,
  useUploadFileAndUpdateStipulation,
} from '../../hooks/useStipulations';
import { Stipulation } from '../../types/stipulations';
import WFDLogo from '../../components/Logo';

const UploadPage = () => {
  const params = useParams<{
    dealId: string;
    type: 'applicant' | 'coApplicant';
  }>();
  const dealId = params.dealId;
  const type = params?.type;
  const {
    data,
    isLoading,
    error: getStipulationsError,
  } = useGetStipulations(
    { query: { dealId, type } },
    dealId !== undefined && type !== undefined
  );
  React.useEffect(() => {
    document.title = 'Upload application files';
  }, []);
  if (getStipulationsError) {
    return <ErrorFullScreen />;
  }
  if (isLoading || !data?.stipulations || !data.names) {
    return <LoadingFullScreen />;
  }
  return (
    <div className="flex items-center w-full p-10 h-screen  flex-col gap-y-5">
      <div>
        <WFDLogo />
      </div>
      <div className="xl:w-1/4 lg:w-1/4  w-full mb-10 flex flex-wrap gap-3 sm:gap-5 rounded-md bg-[#F5F7FB] py-6 px-8">
        <div className="flex flex-col gap-y-2">
          <div className="font-medium text-xl text-left">
            Hello {data.names},
          </div>
          <div className="font-medium text-xl text-left">
            In order to process your loan application we need you to upload the
            following documents:
          </div>
        </div>
      </div>
      {data.stipulations.map((stipulation, index) => {
        return <UploadFileCard key={index} stipulation={stipulation} />;
      })}
    </div>
  );
};
export default UploadPage;

const LoadingFullScreen = () => {
  return (
    <div className="flex items-center justify-center w-full h-screen">
      <div className="flex justify-center items-center space-x-1 text-sm text-gray-700">
        <svg
          fill="none"
          className="w-6 h-6 animate-spin"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
            fill="currentColor"
            fill-rule="evenodd"
          />
        </svg>

        <div>Loading ...</div>
      </div>
    </div>
  );
};
const ErrorFullScreen = () => {
  return (
    <div className="flex items-center justify-center w-full h-screen">
      <div className="flex justify-center flex-col  items-center space-x-1 text-sm text-gray-700">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-red-600"
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="currentColor"
            d="M16.34 9.322a1 1 0 1 0-1.364-1.463l-2.926 2.728L9.322 7.66A1 1 0 0 0 7.86 9.024l2.728 2.926-2.927 2.728a1 1 0 1 0 1.364 1.462l2.926-2.727 2.728 2.926a1 1 0 1 0 1.462-1.363l-2.727-2.926 2.926-2.728z"
          />
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm11 9a9 9 0 1 1 0-18 9 9 0 0 1 0 18z"
            clipRule="evenodd"
          />
        </svg>

        <div>Something went wrong, please try again later.</div>
      </div>
    </div>
  );
};
const SuccessIcon = () => {
  return (
    <div className="w-12  rounded-full bg-green-200 p-2 flex ">
      <svg
        aria-hidden="true"
        className="w-8 h-8 text-green-600"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

const LoadingIcon = () => {
  return (
    <div className="w-12 rounded-full bg-blue-200 p-2 flex justify-center items-center">
      <svg
        width="1em"
        height="1em"
        className="w-8 h-8 text-blue-600 animate-spin"
        viewBox="0 0 1024 1024"
        fill="currentColor"
      >
        <path d="M512 1024c-69.1 0-136.2-13.5-199.3-40.2C251.7 958 197 921 150 874c-47-47-84-101.7-109.8-162.7C13.5 648.2 0 581.1 0 512c0-19.9 16.1-36 36-36s36 16.1 36 36c0 59.4 11.6 117 34.6 171.3 22.2 52.4 53.9 99.5 94.3 139.9 40.4 40.4 87.5 72.2 139.9 94.3C395 940.4 452.6 952 512 952c59.4 0 117-11.6 171.3-34.6 52.4-22.2 99.5-53.9 139.9-94.3 40.4-40.4 72.2-87.5 94.3-139.9C940.4 629 952 571.4 952 512c0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.2C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3s-13.5 136.2-40.2 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7-63.1 26.8-130.2 40.3-199.3 40.3z" />
      </svg>
    </div>
  );
};

const ErrorIcon = () => {
  return (
    <div className="w-12 rounded-full bg-red-200 p-2 flex justify-center items-center">
      <svg
        aria-hidden="true"
        className="w-8 h-8 text-red-600"
        fill="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M16.34 9.322a1 1 0 1 0-1.364-1.463l-2.926 2.728L9.322 7.66A1 1 0 0 0 7.86 9.024l2.728 2.926-2.927 2.728a1 1 0 1 0 1.364 1.462l2.926-2.727 2.728 2.926a1 1 0 1 0 1.462-1.363l-2.727-2.926 2.926-2.728z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm11 9a9 9 0 1 1 0-18 9 9 0 0 1 0 18z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

type UploadFileCardProps = {
  stipulation: Stipulation;
};
const UploadFileCard = ({ stipulation }: UploadFileCardProps) => {
  const inputFile = React.useRef<HTMLInputElement | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );
  const [error, setError] = React.useState(false);
  const {
    uploadFileAndUpdateStipulation,
    success,
    error: uploadError,
    loading,
  } = useUploadFileAndUpdateStipulation(() => setError(false));

  React.useEffect(() => {
    if (uploadError) {
      setErrorMessage('Something went wrong, please try again.');
      setError(true);
    } else {
      setErrorMessage(undefined);
      setError(false);
    }
  }, [uploadError]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e?.target?.files?.length) {
      const file = e.target.files[0];
      if (['image/jpeg', 'image/png', 'application/pdf'].includes(file.type)) {
        uploadFileAndUpdateStipulation({
          stipulationId: stipulation._id,
          file: file,
        });
      } else {
        setErrorMessage(
          'Please upload a valid file - supported types are JPG, PNG, PDF'
        );
        setError(true);
      }
    }
  };

  return (
    <div className="xl:w-1/4 lg:w-1/4  w-full  flex  flex-wrap items-center gap-2 sm:gap-5 rounded-md bg-[#F5F7FB] py-6 px-8">
      <div className=" w-full flex  items-center justify-between gap-x-2 ">
        <div className="flex">
          <span className="font-medium text-lg">
            {stipulation.data.info.description}
          </span>
        </div>

        <div className="flex items-center h-16 space-x-2">
          {!loading &&
            !success &&
            stipulation?.data?.info?.url === 'not_uploaded' && (
              <div className="h-16 flex items-center ">
                <input
                  onClick={(e) => {
                    (e.target as EventTarget & { value: null }).value = null;
                  }}
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                  ref={inputFile}
                  type="file"
                  id={`upload_file_${stipulation._id}`}
                  hidden
                />
                <label
                  onClick={() => inputFile?.current?.click()}
                  htmlFor="upload_file"
                  className="py-2 px-3 inline-flex cursor-pointer items-center gap-x-2 text-xs font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                >
                  <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="17 8 12 3 7 8"></polyline>
                    <line x1="12" x2="12" y1="3" y2="15"></line>
                  </svg>
                  Upload file
                </label>
              </div>
            )}
          {success || stipulation?.data?.info?.url === 'uploaded' ? (
            <SuccessIcon />
          ) : loading ? (
            <LoadingIcon />
          ) : error ? (
            <ErrorIcon />
          ) : null}
        </div>
      </div>
      {error && errorMessage && !success && !loading && (
        <div className="flex flex-col text-xs text-red-600  text-center  w-full">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
